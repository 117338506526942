import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Container from "@components/layout/container"
import media from "@styles/media"
import styled, { useTheme } from "styled-components"
import Heading from "@components/elements/heading"
import Span from "@components/elements/span"
import Div from "@components/elements/div"
import Grid from "@components/elements/grid"
import Flex from "@components/elements/flex"
import Paragraph from "@components/elements/paragraph"
import Button from "@components/elements/button"
import Image from "@components/image"
import { graphql } from "gatsby"
import { processImages } from "@utils/process-images"
import {
  ALT_GRID_SETTINGS,
  GRID_SETTINGS,
  HEADING_SIZE,
  IMAGE_SETTINGS,
  PARAGRAPH_SIZE,
} from "@components/pages/stripe/stripe-constants"
import { ReactComponent as LottieStill } from "@images/lottie-screenshot.svg"

const HeroImage = styled(Flex)`
  height: 100%;
  z-index: 1;
`

const IconWrapper = styled.div`
  svg {
    align-self: flex-start;
    max-height: 120px;
  }

  padding: 0 1rem;
`

const Wrapper = styled(Flex)`
  ${media.md`
    order: -1;
    margin: 6rem 0 4rem;
    align-items: center;
    height: 500px;
    ${HeroImage} {
      img {
        height: 450px;
      }
    }
  `}
`

const BackgroundImage = styled(Image)`
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 60%, transparent);
`

const StripePMPage = ({ data }) => {
  const images = processImages(data.images.edges)
  const { color, gradient } = useTheme()

  return (
    <Layout
      title="PowerMetrics for the Stripe App Marketplace"
      description="PowerMetrics is now available in the Stripe App Marketplace. Install PowerMetrics in your Stripe account for visibility into full funnel business performance without ever leaving the Stripe Dashboard."
      fullWidth
      marginless
      hideOverflow
    >
      <Container fullWidth noPadding>
        <Flex gap="12rem" gapMd="8rem" margin="0 0 8rem">
          <Div background="#21264f">
            <Grid
              maxWidth="1400px"
              columnsMd="1fr"
              alignItems="center"
              columns="1fr 1fr"
              margin="0 auto 4rem"
            >
              <Flex gap="2rem" padding="0 2rem">
                <Heading color="white">
                  Your full business funnel&nbsp;
                  <Span color="#F28888">all in Stripe</Span>
                </Heading>
                <Paragraph lineHeight={1.7} fontSize="1.5rem" color="white">
                  From the money in your bank account to the traffic on your
                  website, monitor your entire business funnel&nbsp;
                  <b>right in Stripe</b>.
                </Paragraph>
                <div>
                  <Button.PowerMetrics text arrow>
                    Get Started Free
                  </Button.PowerMetrics>
                </div>
              </Flex>
              <Wrapper
                position="relative"
                height="700px"
                margin="4rem 0 6rem"
                marginMd="0"
              >
                <HeroImage
                  position="absolute"
                  justifyContent="center"
                  margin="0 0 0 4rem"
                  marginMd="0"
                >
                  <Image
                    file={images["035ee2ba-15d9-4160-94bf-a1aa2942cedb"]}
                  />
                </HeroImage>
                <BackgroundImage
                  file={images["30e8fa4d-f01c-4cac-978d-42613926498c"]}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                    borderRadius: "0 0 0 3rem",
                    overflow: "hidden",
                    zIndex: 0,
                    webkitMaskImage:
                      "linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))",
                  }}
                />
                {/* Image Container */}
              </Wrapper>
            </Grid>
            <Grid
              maxWidth="1400px"
              columnsMd="1fr"
              gap="2rem"
              alignItems="center"
              alignContent="center"
              justifyItems="center"
              justifyContent="center"
              columns="1fr 1fr"
              margin="0 auto 6rem"
            >
              <Div width="100%">
                <LottieStill />
              </Div>
              <Flex gap="2rem" padding="0 2rem">
                <Heading color="white">
                  Complete financial reporting&nbsp;
                  <Span color="#F28888">on one dashboard</Span>
                </Heading>
                <Paragraph fontSize="1.5rem" color="white" lineHeight={1.7}>
                  Install the PowerMetrics app right from your Stripe Dashboard.
                  Connect to a data source like Xero, QuickBooks, Google
                  Analytics, or HubSpot, to start tracking all of your business
                  metrics in one place.
                </Paragraph>
                <div>
                  <Button
                    notice={false}
                    as="a"
                    href="https://marketplace.stripe.com/apps/klipfolio-powermetrics"
                    background={gradient.powerMetricButton}
                    color={color.indigo600}
                    arrow
                  >
                    Go To Stripe
                  </Button>
                </div>
              </Flex>
            </Grid>
          </Div>
          <IconWrapper>
            <Div
              background="linear-gradient(135deg, #b175f9 0%, #3271e0 100%)"
              borderRadius="2rem"
              width="100%"
              maxWidth="1500px"
            >
              <Flex gap="4rem" container padding="5rem 2rem">
                <Heading color="white" as="h2" fontSize="4rem">
                  From revenue
                  <br />
                  to web traffic
                  <br />
                  <Span color="white" opacity={0.7}>
                    all in Stripe
                  </Span>
                </Heading>
                <Grid gap="4rem 2rem" columns="1fr 1fr">
                  <Flex gap="2rem">
                    <Image
                      height={60}
                      width={60}
                      file={images["ee5916d6-5a19-4999-ac48-831fffe888ea"]}
                    />
                    <Heading color="white" as="h4">
                      Track Payments
                    </Heading>
                    <Paragraph color="white">
                      View and segment your Stripe payment & subscription data
                      to break it down by product, card type, or country
                    </Paragraph>
                  </Flex>
                  <Flex gap="2rem">
                    <Image
                      height={60}
                      width={60}
                      file={images["86579260-1952-43ee-89cd-eeafb3c5e82f"]}
                    />
                    <Heading color="white" as="h4">
                      Reconcile Funds
                    </Heading>
                    <Paragraph color="white">
                      Check account balances, reconciliations and expense
                      allocations over specific time periods
                    </Paragraph>
                  </Flex>
                  <Flex gap="2rem">
                    <Image
                      height={60}
                      width={60}
                      file={images["eca9f702-bf70-4950-ab73-4d115228effb"]}
                    />
                    <Heading color="white" as="h4">
                      Analyze Traffic
                    </Heading>
                    <Paragraph color="white">
                      Track web traffic volume and conversion rate to forecast
                      potential revenue
                    </Paragraph>
                  </Flex>
                  <Flex gap="2rem">
                    <Image
                      height={60}
                      width={60}
                      file={images["60b2c073-08e0-4de9-9280-383492d9fd1a"]}
                    />
                    <Heading color="white" as="h4">
                      Forecast Revenue
                    </Heading>
                    <Paragraph color="white">
                      Identify trends and patterns at any point in your business
                      funnel
                    </Paragraph>
                  </Flex>
                </Grid>
              </Flex>
            </Div>
          </IconWrapper>
          <Container>
            <Flex gap="12rem" gapMd="8rem">
              <Grid {...GRID_SETTINGS}>
                <Flex gap="2rem" alignItems="flex-start">
                  <Heading as="h2" fontSize={HEADING_SIZE}>
                    Forecast <Span color="#655cff">revenue growth</Span>
                  </Heading>
                  <Image
                    file={images["457bcaac-c23f-4b95-afe7-1a7087298cd8"]}
                  />
                  <Paragraph fontSize={PARAGRAPH_SIZE}>
                    Know what&apos;s going to happen today, tomorrow, and next
                    week and <b>watch the growth</b> happen.
                  </Paragraph>
                  <Paragraph fontSize={PARAGRAPH_SIZE}>
                    Analyze cash-in and cash-out of your bank account,&nbsp;
                    <b>track payment data</b> like new or failed charges, and
                    use your employee headcount to get a full view of your
                    financial growth model.
                  </Paragraph>
                  <Button.PowerMetrics noticeColor={color.indigo600} arrow>
                    Get Started Free
                  </Button.PowerMetrics>
                </Flex>
                <Div
                  {...IMAGE_SETTINGS}
                  background="linear-gradient(180deg, #655cff 0%, #945cff 100%)"
                >
                  <Image
                    file={images["81899071-a377-4d96-9d0f-82dd8ef026ef"]}
                  />
                </Div>
              </Grid>
              <Grid {...ALT_GRID_SETTINGS}>
                <Div
                  {...IMAGE_SETTINGS}
                  background="linear-gradient(135deg, #9a61fe 0%, #a569fd 100%)"
                >
                  <Image
                    file={images["7bd33126-4570-4404-ac5d-cf95ddb5964a"]}
                  />
                </Div>
                <Flex alignItems="flex-start" gap="2rem">
                  <Heading as="h2" fontSize={HEADING_SIZE}>
                    View the health of&nbsp;
                    <Span color="#955cff">your business</Span>
                  </Heading>
                  <Image
                    file={images["68113d7c-4713-4453-8ddf-8952a13736b3"]}
                  />
                  <Paragraph fontSize={PARAGRAPH_SIZE}>
                    From startups to scale ups, it&apos;s important to keep a
                    finger on the pulse of your business.
                  </Paragraph>
                  <Paragraph fontSize={PARAGRAPH_SIZE}>
                    Build a <b>single source of truth</b> that shows where your
                    money is so you have a clear line of sight into your
                    business.
                  </Paragraph>
                  <Button.PowerMetrics noticeColor={color.indigo600} arrow>
                    Get Started Free
                  </Button.PowerMetrics>
                </Flex>
              </Grid>
              <Grid {...GRID_SETTINGS}>
                <Flex gap="2rem" alignItems="flex-start">
                  <Heading as="h2" fontSize={HEADING_SIZE}>
                    Analyze trends <Span color="#aa6efc">in your revenue</Span>
                  </Heading>
                  <Image
                    file={images["845e1732-05cc-45f0-a3e5-4b023c384ce4"]}
                  />
                  <Paragraph fontSize={PARAGRAPH_SIZE}>
                    Go beyond payments and subscription data. Dive into your
                    revenue streams to <b>identify trends</b> at any point in
                    your business funnel.
                  </Paragraph>
                  <Paragraph fontSize={PARAGRAPH_SIZE}>
                    From the dollars that bring in every new lead to&nbsp;
                    <b>forecasted revenue</b>, see how your money flows across
                    every channel.
                  </Paragraph>
                  <Button.PowerMetrics noticeColor={color.indigo600} arrow>
                    Get Started Free
                  </Button.PowerMetrics>
                </Flex>
                <Div
                  {...IMAGE_SETTINGS}
                  background="linear-gradient(-45deg, #d592f7 0%, #bc7df9 100%)"
                >
                  <Image
                    file={images["dfc33778-66ed-404f-bce1-6375fda349cc"]}
                  />
                </Div>
              </Grid>
            </Flex>
          </Container>
          <Container text>
            <Flex maxWidth="600px">
              <Heading
                maxWidth="18ch"
                as="h2"
                fontSize="3rem"
                margin="0 0 1rem"
              >
                Maximize your revenue potential
              </Heading>
              <Paragraph margin="0 0 3rem">
                As your business grows, your data grows with you.
              </Paragraph>
              <Button.PowerMetrics
                noticeColor={color.indigo600}
                alignSelf="flex-start"
              />
            </Flex>
          </Container>
        </Flex>
      </Container>
    </Layout>
  )
}

StripePMPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StripePMPage

export const pageQuery = graphql`
  query PowerMetricsStripeQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "845e1732-05cc-45f0-a3e5-4b023c384ce4"
            "457bcaac-c23f-4b95-afe7-1a7087298cd8"
            "845e1732-05cc-45f0-a3e5-4b023c384ce4"
            "ee5916d6-5a19-4999-ac48-831fffe888ea"
            "eca9f702-bf70-4950-ab73-4d115228effb"
            "60b2c073-08e0-4de9-9280-383492d9fd1a"
            "86579260-1952-43ee-89cd-eeafb3c5e82f"
            "dfc33778-66ed-404f-bce1-6375fda349cc"
            "7bd33126-4570-4404-ac5d-cf95ddb5964a"
            "81899071-a377-4d96-9d0f-82dd8ef026ef"
            "035ee2ba-15d9-4160-94bf-a1aa2942cedb"
            "30e8fa4d-f01c-4cac-978d-42613926498c"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
